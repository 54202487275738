@charset "utf-8";

@import "../../node_modules/bulma/sass/utilities/initial-variables";

// Import a Google Font
@import url("https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap");

$family-sans-serif: "Roboto", sans-serif;

// NHS UK

$nhsuk-text-color: #212b32;
$nhsuk-secondary-text-color: #4c6272;
$nhsuk-link-color: #005eb8;
$nhsuk-link-hover-color: #7c2855;
$nhsuk-link-visited-color: #330072;
$nhsuk-link-active-color: #002f5c;
$nhsuk-focus-color: #ffeb3b;
$nhsuk-focus-text-color: #212b32;
$nhsuk-border-color: #d8dde0;
$nhsuk-form-border-color: #4c6272;
$nhsuk-error-color: #d5281b;
$nhsuk-button-color: #007f3b;
$nhsuk-secondary-button-color: #4c6272;
$color_nhsuk-grey-5: #f0f4f5;
$color_nhsuk-grey-3: #aeb7bd;

// Material UI

$primary-color: $nhsuk-link-color;
$primary-dark: #003586;
$primary-light: #5689ea;

// OpenRDT

$section-vertical-padding: 30px;
$strong-weight: 500;

// Bulma

$black: $nhsuk-text-color;

$body-background-color: "white";
$body-color: $nhsuk-text-color;
$body-font-size: 17px;

$box-color: inherit;

$card-header-background-color: $primary-color;
$card-header-color: white;

$danger: $nhsuk-error-color;

$label-color: $body-color;

$link: $nhsuk-link-color;
$link-hover: $nhsuk-link-color;

$navbar-background-color: $primary-color;
$navbar-tab-active-background-color: $primary-dark;
$navbar-item-color: white;
$navbar-item-hover-color: white;
$navbar-item-hover-background-color: $primary-dark;

$section-padding: $section-vertical-padding 1.5rem;
$section-padding-medium: 90px 1.5rem;
$section-padding-large: 180px 1.5rem;

$strong-color: $body-color;

$text-strong: $body-color;

$title-color: $body-color;
$title-size: 24px;
$title-weight: 300;

// Import only what you need from Bulma
@import "../../node_modules/bulma/sass/utilities/_all.sass";
@import "../../node_modules/bulma/sass/base/_all.sass";

@import "../../node_modules/bulma/sass/components/media.sass";
@import "../../node_modules/bulma/sass/elements/container.sass";
@import "../../node_modules/bulma/sass/elements/content.sass";
@import "../../node_modules/bulma/sass/elements/icon.sass";
@import "../../node_modules/bulma/sass/elements/title.sass";
@import "../../node_modules/bulma/sass/form/_all.sass";
@import "../../node_modules/bulma/sass/layout/section.sass";

.media-left {
  color: $body-color;
}

.has-background-link .media-left {
  color: white;
}

a {
  font-weight: $strong-weight;
}

a:hover {
  color: $nhsuk-link-hover-color;
}

.content ul {
  margin-left: 0;
  list-style-type: square;
}

.section + .section {
  padding-top: 0;
}

html.BlueBG {
  background-color: $primary-color;
}

html.BlueBG .openRDTFooter a,
html.BlueBG .openRDTFooter {
  color: white;
}

:export {
  bodycolor: $body-color;
  bodybackgroundcolor: $body-background-color;
  bodyfontsize: $body-font-size;

  dividercolor: $color_nhsuk-grey-3;

  errorcolor: $nhsuk-error-color;

  linkcolor: $link;
  linkhovercolor: $link-hover;

  navbackarrowcolor: #fff;

  primarycolor: $primary-color;
  primarylight: $primary-light;
  primarydark: $primary-dark;
  primarytextcolor: $body-color;

  secondarytextcolor: $nhsuk-secondary-text-color;

  sectionverticalpadding: $section-vertical-padding;

  strongweight: $strong-weight;

  progresscolor: $primary-color;
  progressbackgrouncolor: $nhsuk-border-color;

  titlecolor: $title-color;
}
